import './style.scss';

import { useState, FC } from 'react';

import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import { MQNavAccordionItemProps } from '../../types';

const MQNavAccordionItem: FC<MQNavAccordionItemProps> = ({
  title,
  content,
  className,
  startIcon,
  showIcon,
  onClick,
  badge,
  dataTestId = 'mq-nav-accordion-item',
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      data-testid={dataTestId}
      {...props}
      className={classNames('mq-nav-accordion-item', className, { open: isOpen })}
      onClick={onClick}
    >
      <div className="mq-nav-accordion-item_title" onClick={toggleAccordion}>
        {startIcon && <div className="mq-nav-accordion-item_start-icon">{startIcon}</div>}
        {badge && <div className="mq-nav-accordion-item_badge">{badge}</div>}
        <div className="mq-nav-accordion-item_label" data-testid="mq-nav-accordion-item-label">
          {title}
        </div>
        {showIcon && (
          <MQIcon.Svg data-testid={`mq-sidebar-accordion-toggle-icon`} icon={isOpen ? 'chevron-up' : 'chevron-down'} />
        )}
      </div>
      <div className="mq-nav-accordion-item_content">{content}</div>
    </div>
  );
};

export default MQNavAccordionItem;
