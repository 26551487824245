import { useCallback, useMemo } from 'react';

import { useIntl, FormatDateOptions } from 'react-intl';

import { DateValueType } from 'types/global';

import { mapDateFormatToOptions } from 'helpers/date';
import { SHA256 } from 'helpers/string';

export const useAppIntl = () => {
  const provider = useIntl();

  const dynamicMessage = useCallback(
    ({
      id,
      defaultMessage = '',
      values = {},
      tokens = {},
    }: {
      id?: string;
      defaultMessage?: string | null;
      values?: Record<string, string | number | null>;
      tokens?: Record<string, string | null>;
    }) => {
      const fallback = defaultMessage ?? '';
      const hash = SHA256(fallback);
      const key = id ?? hash ?? fallback;

      const translation = provider.messages[key] || fallback;

      const formatted = Object.entries(values).reduce((prev, [key, value]) => {
        if (value !== null) {
          return prev.replaceAll(`{${key}}`, String(value));
        }

        return prev;
      }, `${translation}`);

      return Object.entries(tokens).reduce((acc, [key, value]) => {
        if (value) {
          return acc.replaceAll(key, value);
        }

        return acc;
      }, formatted);
    },
    [provider.messages],
  );

  const dynamicRecord = useCallback(
    (record: Record<string, string>) =>
      Object.entries(record).reduce((prev, [key, value]) => {
        const messageHash = SHA256(value);
        const phraseHash = SHA256(key);
        const message = `${provider.messages[messageHash] || value}`;
        const phrase = `${provider.messages[phraseHash] || key}`;

        return {
          ...prev,
          [phrase]: message,
        };
      }, {}),
    [provider.messages],
  );

  const localizedDate = useCallback(
    (date: DateValueType, options: FormatDateOptions = {}) =>
      provider.formatDate(date, mapDateFormatToOptions(options)),
    [provider],
  );

  return useMemo(
    () =>
      Object.assign(provider, {
        dynamicMessage,
        dynamicRecord,
        localizedDate,
      }),
    [provider, dynamicMessage, dynamicRecord, localizedDate],
  );
};
