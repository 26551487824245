import './style.scss';

import { FC, ReactNode } from 'react';

import { MQIcon } from '@mentorcliq/ui';

interface MQFormatterProps {
  prefix?: ReactNode;
  suffix?: ReactNode;
  infoIcon?: boolean;
  children: ReactNode;
}

const MQFormatter: FC<MQFormatterProps> = ({ prefix, suffix, infoIcon = false, children }) => (
  <>
    {infoIcon && (
      <span className="mq-formatter__prefix">
        <MQIcon.Svg icon="info-circle" variant="indigo" />
      </span>
    )}
    {!!prefix && <span className="mq-formatter__prefix">{prefix}</span>}
    {children}
    {!!suffix && <span className="mq-formatter__suffix">{suffix}</span>}
  </>
);

export default MQFormatter;
