import { RouteProps } from 'react-router-dom';

import { AccessParamsDto } from 'types/access';

export enum RouteTypes {
  Common,
  Base,
  Admin,
  Verified,
  Enrolled,
  GdprAccepted,
  Authenticated,
  UnAuthenticated,
  LearningLab,
  Preview,
}

export enum LayoutSizeTypes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum LayoutTypesEnum {
  Header = 'header',
  Footer = 'footer',
  Sidebar = 'sidebar',
  Alerts = 'alerts',
  Banner = 'banner',
  Scroll = 'scroll',
  Support = 'support',
  Chat = 'chat',
  Cookies = 'cookies',
}

export interface RouteParamsDefinition {
  types: RouteTypes[];
  layoutSize?: LayoutSizeTypes;
  layoutTypes: LayoutTypesEnum[];
  access?: AccessParamsDto;
}

export interface RouteDataDefinition extends RouteProps {
  path: string;
  bucket?: boolean;
}

export interface RouteBaseDefinition {
  data: RouteDataDefinition;
  params: RouteParamsDefinition;
  redirectTo?: RouteDataDefinition;
}

export type RoutePathParams = Record<string, string | number | null | undefined>;

export type RouteQueryParams = Record<string, string | number | null | undefined>;

export interface RouteDefinition extends RouteBaseDefinition {
  convert: (params?: { path?: RoutePathParams; search?: RouteQueryParams }) => string;
  oneOfType: (params: RouteTypes[]) => boolean;
}
