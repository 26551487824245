import { FC, useEffect, useRef, useState } from 'react';

import { MQIcon } from '@mentorcliq/ui';
import { debounce, isEqual } from 'lodash';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppIntl } from 'hooks/useAppIntl';

import MQForm from 'modules/MQForm';

interface ChatFiltersDataProps {
  keyword: string;
}

interface ChatFiltersProps {
  filters: ChatFiltersDataProps;
  setFilters: (data: ChatFiltersDataProps) => void;
  delay?: number;
}

const ChatFilters: FC<ChatFiltersProps> = ({ filters, setFilters, delay = 500 }) => {
  const intl = useAppIntl();
  const initialData = useRef(filters);
  const [data, setData] = useState(filters);

  const dataDebounce = useRef(debounce((newFilters) => data !== newFilters && setFilters(newFilters), delay));

  useEffect(() => {
    if (!isEqual(initialData.current, filters)) {
      setData((prev) => ({
        ...prev,
        ...filters,
      }));

      initialData.current = filters;
    }
  }, [filters]);

  useEffect(() => {
    dataDebounce.current(data);
  }, [data]);

  return (
    <div className="app-chat-filters">
      <MQForm.InputGroup>
        <MQForm.InputGroup.Prepend>
          <MQForm.InputGroup.Text>
            <MQIcon.Svg icon="search__r" />
          </MQForm.InputGroup.Text>
        </MQForm.InputGroup.Prepend>
        <MQForm.Input
          name="keword"
          value={data.keyword}
          placeholder={intl.formatMessage(APP_GLOBAL_MESSAGES.globalSearchByNameLabel)}
          dataTestId="chat-rooms-keyword-search"
          onChange={(e) => {
            const keyword = e.target.value;
            setData((prev) => ({
              ...prev,
              keyword,
            }));
          }}
        />
      </MQForm.InputGroup>
    </div>
  );
};

export default ChatFilters;
