import './style.scss';

import { FC, ReactNode } from 'react';

import classNames from 'classnames';

interface MQFormFieldProps {
  className?: string;
  children?: ReactNode;
  append?: ReactNode;
  prepend?: ReactNode;
}

const MQFormField: FC<MQFormFieldProps> = ({ className = '', children, append, prepend }) => (
  <div className={classNames('mq-form-field', className)}>
    {!!append && <div className="mq-form-field__append">{append}</div>}
    <div className="mq-form-field__input">{children}</div>
    {!!prepend && <div className="mq-form-field__append">{prepend}</div>}
  </div>
);

export default MQFormField;
