import './style.scss';

import { FC, useRef, useEffect, useMemo, useState, useCallback } from 'react';

import classNames from 'classnames';

import { MQFormEditAreaProps } from '../../types';

const MQFormEditArea: FC<MQFormEditAreaProps> = ({
  dataTestId = 'mq-form-edit-area',
  ariaLabel = 'Editable text area',
  data,
  onChange,
  onBlur,
  isValid,
  isInvalid,
  resize,
  inValidData,
  separator,
  ...props
}) => {
  const editableDiv = useRef<HTMLDivElement>(null);
  const prevValidState = useRef({ dataLength: inValidData?.length ?? 0, isValid });
  const [userTyping, setUserTyping] = useState(false);

  const highlightInvalidEmails = useMemo(() => {
    if (data.trim() === '') {
      return '';
    }

    const elements = data.split(separator);

    return elements
      .map((element) => {
        if (isInvalid && inValidData?.includes(element.trim())) {
          return `<span class="invalid-data">${element.trim()}</span>`;
        }
        return element;
      })
      .join('');
  }, [data, inValidData, isInvalid, separator]);

  const getCursorPosition = () => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      let position = 0;
      let currentNode = range.startContainer;

      while (currentNode && editableDiv.current?.innerHTML && currentNode !== editableDiv.current) {
        if (currentNode.previousSibling) {
          position += currentNode.previousSibling.textContent?.length ?? 0;
          currentNode = currentNode.previousSibling;
        } else if (currentNode.parentNode) {
          currentNode = currentNode.parentNode;
        }
      }

      return position + range.startOffset;
    }
    return null;
  };

  const findTextNodeAtOffset = (root: Node, offset: number) => {
    let currentOffset = 0;

    const traverse = (node: Node): { node: Node | null; offset: number } => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textLength = node.textContent?.length ?? 0;
        if (currentOffset + textLength >= offset) {
          return { node, offset: offset - currentOffset };
        }
        currentOffset += textLength;
      } else {
        for (const childNode of node.childNodes) {
          const result = traverse(childNode);
          if (result.node) return result;
        }
      }
      return { node: null, offset: 0 };
    };

    return traverse(root);
  };

  const setCursorAtIndex = useCallback((index: number) => {
    if (editableDiv.current) {
      const { node, offset } = findTextNodeAtOffset(editableDiv.current, index);
      if (node) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.setStart(node, offset);
        range.setEnd(node, offset);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }
  }, []);

  const updateAndSetPosition = useCallback(() => {
    if (editableDiv.current) {
      const currentCursorPos = getCursorPosition();
      editableDiv.current.innerHTML = isInvalid ? highlightInvalidEmails : data;

      if (currentCursorPos !== null) {
        setCursorAtIndex(currentCursorPos);
      }
    }
  }, [data, highlightInvalidEmails, isInvalid, setCursorAtIndex]);

  useEffect(() => {
    if (!userTyping) {
      updateAndSetPosition();
    }
  }, [updateAndSetPosition, userTyping]);

  useEffect(() => {
    const inValidDataLength = inValidData?.length ?? 0;
    if (inValidDataLength !== prevValidState.current.dataLength || (isInvalid && !prevValidState.current.isValid)) {
      updateAndSetPosition();
      prevValidState.current = { ...prevValidState.current, dataLength: inValidDataLength };
    }
  }, [inValidData, isInvalid, updateAndSetPosition]);

  const handleInput = () => {
    if (editableDiv.current) {
      const cursorOffset = getCursorPosition();
      const textContent = editableDiv.current.innerText;

      onChange(textContent);

      if (cursorOffset !== null) {
        setCursorAtIndex(cursorOffset);
      }
    }
    setUserTyping(true);
  };

  const handleBlur = () => {
    setUserTyping(false);
    onBlur?.();
  };

  return (
    <div
      {...props}
      data-testid={dataTestId}
      ref={editableDiv}
      role="textbox"
      aria-multiline="true"
      aria-label={ariaLabel}
      suppressContentEditableWarning
      className={classNames(
        'mq-form-edit-area',
        { 'is-valid': isValid, 'is-invalid': isInvalid },
        resize && `resize-${resize}`,
      )}
      onInput={handleInput}
      onBlur={handleBlur}
      contentEditable
    />
  );
};

export default MQFormEditArea;
