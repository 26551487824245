import './style.scss';

import { FC, ReactNode } from 'react';

import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';
import { noop } from 'lodash';

export type AlertVariantsUnion = 'success' | 'danger' | 'warning' | 'info' | string;

export interface MQAlertProps {
  onClose?: () => void;
  className?: string;
  dismissible?: boolean;
  message?: ReactNode;
  helpText?: ReactNode;
  variant: AlertVariantsUnion;
  enableReinitialize?: boolean;
  button?: ReactNode;
  children?: ReactNode;
}

export const MQAlert: FC<MQAlertProps> = ({
  className = '',
  variant,
  children,
  dismissible = false,
  message,
  onClose = noop,
  button,
}) => {
  const handleClose = () => {
    onClose();
  };

  let alertMessageIcon = null;
  let alertBackgroundIcon = null;

  switch (variant) {
    case 'success': {
      alertMessageIcon = <MQIcon.Svg icon="check-circle" className="mq-alert__icon" />;
      alertBackgroundIcon = <MQIcon.Svg icon="check-circle__r" className="mq-alert__icon" />;
      break;
    }
    case 'warning': {
      alertMessageIcon = <MQIcon.Svg icon="triangle-exclamation" className="mq-alert__icon" />;
      alertBackgroundIcon = <MQIcon.Svg icon="exclamation-triangle__r" className="mq-alert__icon" />;
      break;
    }
    case 'danger': {
      alertMessageIcon = <MQIcon.Svg icon="exclamation-circle" className="mq-alert__icon" />;
      alertBackgroundIcon = <MQIcon.Svg icon="exclamation-circle__r" className="mq-alert__icon" />;
      break;
    }
    case 'info': {
      alertMessageIcon = <MQIcon.Svg icon="info-circle" className="mq-alert__icon" />;
      alertBackgroundIcon = <MQIcon.Svg icon="info-circle__r" className="mq-alert__icon" />;
      break;
    }
  }

  return (
    <div
      className={classNames(['mq-alert', className, `${variant}`, { dismissible }])}
      data-testid={`alert-${variant}`}
    >
      {alertMessageIcon}
      <div className="mq-alert__container">
        {message && <span className="mq-alert__heading">{message}</span>}
        {children && <div className="mq-alert__body">{children}</div>}
        {button && <div className="mq-alert__button">{button}</div>}
      </div>
      <div className="mq-alert__overlay">{alertBackgroundIcon}</div>
      {dismissible && (
        <div className="mq-alert__close" onClick={handleClose}>
          <MQIcon.Svg icon="times" />
        </div>
      )}
    </div>
  );
};

export default MQAlert;
