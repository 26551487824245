import { FC, useMemo } from 'react';

import { useAppIntl } from 'hooks/useAppIntl';

interface AppDynamicMessageProps {
  id?: string;
  defaultMessage?: string | null;
  values?: Record<string, any>;
}

const AppDynamicMessage: FC<AppDynamicMessageProps> = ({ id, defaultMessage = '', values = {} }) => {
  const intl = useAppIntl();

  const translation = useMemo(
    () =>
      intl.dynamicMessage({
        id,
        defaultMessage,
        values,
      }),
    [defaultMessage, id, values, intl],
  );

  if (translation) {
    return <>{translation}</>;
  }

  return null;
};

export default AppDynamicMessage;
