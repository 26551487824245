import { FC } from 'react';

import { FeatureTypeEnum, PermissionsEnum } from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'routes';

import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppConfigs } from 'hooks/useAppConfigs';

import MQSidebar from 'modules/MQSidebar';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import AppLink from 'router/AppLink';

import PermissionWrapper from 'components/PermissionWrapper';

const Platform: FC = () => {
  const { publicConfigs } = useAppConfigs();

  return (
    <MQSidebar.List data-testid="sidebar-platform" className="app-sidebar__list">
      <MQSidebar.ListItem
        as={AppLink}
        data-testid="sidebar-back-link"
        className="app-sidebar__list-item"
        to={ROUTES.adminDashboard.data.path}
        prefix={<MQIcon.Svg icon="long-arrow-alt-left" />}
      >
        <AppFormattedMessage {...APP_GLOBAL_MESSAGES.adminDashboard} />
      </MQSidebar.ListItem>
      <PermissionWrapper
        permissions={{
          value: [
            PermissionsEnum.ManagePlatformInfo,
            PermissionsEnum.ViewAddOns,
            PermissionsEnum.UpdateAddOns,
            PermissionsEnum.MentorcliqBranding,
            PermissionsEnum.ManagePlatformResources,
            PermissionsEnum.ManagePlatformConfiguration,
            PermissionsEnum.ManageFeatures,
            PermissionsEnum.ManageDynamicTranslations,
            PermissionsEnum.ManageBaseTranslations,
          ],
        }}
      >
        <MQSidebar.ListItem>
          <MQSidebar.List>
            <MQSidebar.ListItem>
              <MQSidebar.Heading className="app-sidebar__heading">
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformSettingsTitle} />
              </MQSidebar.Heading>
            </MQSidebar.ListItem>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManagePlatformInfo] }}>
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-platform-link"
                to={ROUTES.platformInfoConfiguration.data.path}
                className="app-sidebar__list-item"
                prefix={<MQIcon.Svg icon="info-circle__r" />}
              >
                <FormattedMessage defaultMessage="Info" description="[Sidebar] Menu Item Name" />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper
              permissions={{ value: [PermissionsEnum.ViewAddOns, PermissionsEnum.UpdateAddOns] }}
              strict={false}
            >
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-addons-link"
                className="app-sidebar__list-item"
                to={ROUTES.addonsConfiguration.data.path}
                prefix={<MQIcon.Svg icon="puzzle-piece__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.addOnsLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.MentorcliqBranding] }}>
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-branding-link"
                className="app-sidebar__list-item"
                to={ROUTES.brandingConfiguration.data.path}
                prefix={<MQIcon.Svg icon="palette__r" />}
              >
                <FormattedMessage defaultMessage="Branding" description="[Sidebar] Menu Item Name" />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper
              features={{ value: [FeatureTypeEnum.ResourceLibrary] }}
              permissions={{ value: [PermissionsEnum.ManagePlatformResources] }}
              strict
            >
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-resources-link"
                className="app-sidebar__list-item"
                to={ROUTES.managePlatformResources.convert({
                  search: {
                    lang: publicConfigs.languages.locale,
                  },
                })}
                prefix={<MQIcon.Svg icon="photo-film__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformManageResourcesLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>

            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManagePlatformConfiguration] }}>
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-global-link"
                className="app-sidebar__list-item"
                to={ROUTES.platformConfiguration.data.path}
                prefix={<MQIcon.Svg icon="gears__r" />}
              >
                <FormattedMessage defaultMessage="Configuration" description="[Sidebar] Menu Item Name" />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageFeatures] }}>
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-gdpr-link"
                className="app-sidebar__list-item"
                to={ROUTES.gdprConfiguration.data.path}
                prefix={<MQIcon.Svg icon="shield__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformGdprPrivacyLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>

            <PermissionWrapper
              permissions={{
                value: [PermissionsEnum.ManageDynamicTranslations, PermissionsEnum.ManageBaseTranslations],
              }}
            >
              <MQSidebar.ListItem
                as={AppLink}
                data-testid="sidebar-configure-languages-link"
                className="app-sidebar__list-item"
                to={ROUTES.languagesConfiguration.data.path}
                prefix={<MQIcon.Svg icon="globe__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformLanguagesLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
          </MQSidebar.List>
        </MQSidebar.ListItem>
      </PermissionWrapper>

      <PermissionWrapper
        permissions={{ value: [PermissionsEnum.ManageProfileSettings, PermissionsEnum.ManageProfile] }}
      >
        <MQSidebar.ListItem>
          <MQSidebar.List>
            <MQSidebar.ListItem>
              <MQSidebar.Heading className="app-sidebar__heading">
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.userDashBoardProfileTabLabel} />
              </MQSidebar.Heading>
            </MQSidebar.ListItem>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageProfileSettings] }}>
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-profilette-link"
                to={ROUTES.profileFields.data.path}
                prefix={<MQIcon.Svg icon="align-left__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformProfileFieldsLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>

            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageProfile] }}>
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-profile-link"
                to={ROUTES.profileDisplay.data.path}
                prefix={<MQIcon.Svg icon="user-circle__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformProfileDisplayLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
          </MQSidebar.List>
        </MQSidebar.ListItem>
      </PermissionWrapper>

      <PermissionWrapper
        permissions={{
          value: [
            PermissionsEnum.ManageHRIS,
            PermissionsEnum.ManageSSOSettings,
            PermissionsEnum.ManageCalendarConfiguration,
            PermissionsEnum.ManageZoomConfiguration,
            PermissionsEnum.ManageCollaborationTools,
          ],
        }}
      >
        <MQSidebar.ListItem>
          <MQSidebar.List className="app-sidebar__list">
            <MQSidebar.ListItem>
              <MQSidebar.Heading className="app-sidebar__heading">
                <FormattedMessage defaultMessage="Integrations" description="[Sidebar] Menu Item Name" />
              </MQSidebar.Heading>
            </MQSidebar.ListItem>
            <PermissionWrapper
              permissions={{ value: [PermissionsEnum.ManageHRIS] }}
              features={{ value: [FeatureTypeEnum.Hris] }}
              strict
            >
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-hris-link"
                to={ROUTES.hrIsConfiguration.data.path}
                prefix={<MQIcon.Svg icon="database__r" />}
              >
                <FormattedMessage
                  defaultMessage="HRIS"
                  description="[Sidebar] Menu Item Name"
                  id="HRIS.Sidebar.menu.label"
                />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper
              permissions={{ value: [PermissionsEnum.ManageSSOSettings] }}
              features={{ value: [FeatureTypeEnum.Sso] }}
              strict
            >
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-sso-link"
                to={ROUTES.ssoConfiguration.data.path}
                prefix={<MQIcon.Svg icon="sign-in__r" />}
              >
                <FormattedMessage defaultMessage="SSO" description="[Sidebar] Menu Item Name" id="platform.sso" />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCalendarConfiguration] }}>
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-calendar-link"
                to={ROUTES.calendarConfiguration.data.path}
                prefix={<MQIcon.Svg icon="calendar__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformCalendarLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageZoomConfiguration] }}>
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-zoom-link"
                to={ROUTES.zoomConfiguration.data.path}
                prefix={<MQIcon.Svg icon="video__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformZoomConfigurationLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
            <PermissionWrapper permissions={{ value: [PermissionsEnum.ManageCollaborationTools] }}>
              <MQSidebar.ListItem
                as={AppLink}
                className="app-sidebar__list-item"
                data-testid="sidebar-configure-collaboration-link"
                to={ROUTES.collaborationConfiguration.data.path}
                prefix={<MQIcon.Svg icon="user-head-set__r" />}
              >
                <AppFormattedMessage {...APP_GLOBAL_MESSAGES.platformCollaborationToolsLabel} />
              </MQSidebar.ListItem>
            </PermissionWrapper>
          </MQSidebar.List>
        </MQSidebar.ListItem>
      </PermissionWrapper>
    </MQSidebar.List>
  );
};

export default Platform;
