import './style.scss';

import { FC, useContext } from 'react';

import { MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import { MQModalContext } from '../../definitions';
import { MQModalHeaderProps } from '../../types';

const MQModalHeader: FC<MQModalHeaderProps> = ({ className = '', icon, closeButton, children }) => {
  const { close } = useContext(MQModalContext);
  return (
    <div data-testid="mq-modal-header" aria-label="Modal Header" className={classNames('mq-modal-header', className)}>
      <div className="mq-modal-header__title">
        {icon && <span className="mq-modal-header__title-icon">{icon}</span>}
        {children}
      </div>
      {closeButton && (
        <button
          className="mq-modal-header__close"
          aria-label="Close button"
          onClick={() => close?.()}
          data-testid="mq-modal-header-close"
        >
          <MQIcon.Svg icon="times" />
        </button>
      )}
    </div>
  );
};

export default MQModalHeader;
