import './style.scss';

import { FC, useCallback, useEffect, useMemo } from 'react';

import { deleteFileThunk, FileEntityDto, getFileSelector, getFileThunk } from '@mentorcliq/storage';
import { MQAudio, MQImage, MQIcon } from '@mentorcliq/ui';

import {
  AUDIO_FILE_UPLOAD_EXTENSIONS,
  DOCUMENT_FILE_UPLOAD_EXTENSIONS,
  IMAGE_FILE_UPLOAD_EXTENSIONS,
  VIDEO_FILE_UPLOAD_EXTENSIONS,
} from 'definitions/files';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import MQButton from 'modules/MQButton';
import MQTypo from 'modules/MQTypo';
import MQVideo from 'modules/MQVideo';

import AppFormattedMessage from 'formatters/AppFormattedMessage';
import AppFormattedNumber from 'formatters/AppFormattedNumber';

interface AppFilePreviewProps {
  uuid: string;
  data?: FileEntityDto | null;
  showInfo?: boolean;
  onRemove?: (uuid: string) => void;
  allowDownload?: boolean;
  onDownload?: (link: string) => void;
  cover?: boolean;
  height?: number;
  width?: number;
  allowDeleteFileFromStorage?: boolean;
  disabled?: boolean;
}

const AppFilePreview: FC<AppFilePreviewProps> = ({
  data,
  uuid,
  height,
  width,
  showInfo = false,
  allowDownload = false,
  onDownload,
  onRemove,
  cover = false,
  allowDeleteFileFromStorage = false,
  disabled,
}) => {
  const dispatch = useAppDispatch();
  const file = useAppSelector(({ files }) => getFileSelector(files, uuid));
  const result = useMemo(() => file || data, [data, file]);

  const formattedFileSize = useMemo(() => {
    const MaxBytesRender = 1024;
    const maxKilobyteRender = 1024 * 1024;
    if (file?.fileSizeBytes < MaxBytesRender) {
      return (
        <AppFormattedMessage
          defaultMessage="{size} Bytes"
          description="[File size bytes]"
          id="app.file.preview.bytes"
          values={{
            size: <AppFormattedNumber number={file?.fileSizeBytes} />,
          }}
        />
      );
    } else if (file?.fileSizeBytes < maxKilobyteRender) {
      return (
        <AppFormattedMessage
          defaultMessage="{size} KB"
          description="[File size kb]"
          id="app.file.preview.kb"
          values={{
            size: (
              <AppFormattedNumber
                number={file?.fileSizeBytes / MaxBytesRender}
                options={{ maximumFractionDigits: 2 }}
              />
            ),
          }}
        />
      );
    } else {
      return (
        <AppFormattedMessage
          defaultMessage="{size} MB"
          description="[File size mb]"
          id="app.file.preview.mb"
          values={{
            size: (
              <AppFormattedNumber
                number={file?.fileSizeBytes / maxKilobyteRender}
                options={{ maximumFractionDigits: 2 }}
              />
            ),
          }}
        />
      );
    }
  }, [file?.fileSizeBytes]);

  useEffect(() => {
    if (!file?.uuid && uuid) {
      dispatch(getFileThunk({ uuid }));
    }
  }, [dispatch, uuid, file?.uuid]);

  const handleRemove = useCallback(() => {
    onRemove?.(uuid);
    allowDeleteFileFromStorage && dispatch(deleteFileThunk({ uuid }));
  }, [dispatch, uuid, onRemove, allowDeleteFileFromStorage]);

  const handleDownload = useCallback(
    (src: string) => {
      if (allowDownload) {
        if (!onDownload) {
          window.open(src, '__blank');
        } else {
          onDownload?.(src);
        }
      }
    },
    [onDownload, allowDownload],
  );

  const preview = useMemo(() => {
    if (result) {
      const extension = result.fileHandle.split('.').pop()?.toLowerCase();

      if (extension) {
        if (Object.keys(IMAGE_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            image: (
              <MQImage
                width={width ?? '100%'}
                height={height ?? 'auto'}
                src={result.fileHandle}
                alt={result.name}
                cover={cover}
              />
            ),
            info: result,
            fileSizeBytes: formattedFileSize,
            cover: false,
          };
        } else if (Object.keys(DOCUMENT_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            file: <MQIcon.Svg icon="file-lines" size="2x" />,
            info: result,
            fileSizeBytes: formattedFileSize,
            cover: true,
          };
        } else if (Object.keys(VIDEO_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            video: (
              <MQVideo
                width={width ?? '100%'}
                height={height ?? '100%'}
                url={result.fileHandle}
                controls
                allowFullScreen
              />
            ),
            info: result,
            fileSizeBytes: formattedFileSize,
            cover: false,
          };
        } else if (Object.keys(AUDIO_FILE_UPLOAD_EXTENSIONS).includes(extension)) {
          return {
            audio: <MQAudio url={result.fileHandle} />,
            info: result,
            fileSizeBytes: formattedFileSize,
            cover: false,
          };
        }
      }
    }
  }, [result, width, height, cover, formattedFileSize]);

  if (preview) {
    return (
      <div className="app-file-preview">
        {!!preview.file && (
          <button
            className="app-file-preview__file"
            onClick={(event) => {
              event.preventDefault();
              handleDownload(preview.info.fileHandle);
            }}
            disabled={disabled}
          >
            {preview.file}
          </button>
        )}
        {!!preview.image && (
          <button
            className="app-file-preview__image"
            onClick={(event) => {
              event.preventDefault();
              handleDownload(preview.info.fileHandle);
            }}
            disabled={disabled}
          >
            {preview.image}
          </button>
        )}
        {!!preview.audio && <div className="app-file-preview__audio">{preview.audio}</div>}
        {!!preview.video && <div className="app-file-preview__video">{preview.video}</div>}
        {!!preview.info && showInfo && (
          <div className="app-file-preview__body">
            <div className="app-file-preview__info" data-testid="app-file-preview-info">
              <MQTypo.Text bold nowrap>
                {preview.info.name}
              </MQTypo.Text>
              <MQTypo.Text italic nowrap>
                {formattedFileSize}
              </MQTypo.Text>
              {!!preview?.cover && allowDownload && (
                <button
                  className="app-file-preview__cover"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDownload(preview.info.fileHandle);
                  }}
                  disabled={disabled}
                >
                  <MQIcon.Svg icon="download__r" className="mq-mr-scale-3" />
                  <AppFormattedMessage {...APP_GLOBAL_MESSAGES.globalDownloadLabel} />
                </button>
              )}
            </div>
            {!!onRemove && (
              <MQButton
                dataTestId="remove-file"
                className="app-file-preview__button"
                variant="danger"
                startIcon={<MQIcon.Svg icon="xmark-circle__r" />}
                onClick={handleRemove}
                shape="square"
                disabled={disabled}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default AppFilePreview;
