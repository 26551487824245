import { FC, useCallback, useMemo } from 'react';

import {
  getSwitchUserCandidates,
  getSwitchUserCandidatesLoadingState,
  getSwitchUserCandidatesTotalElements,
  switchUserCandidatesQueryThunk,
  switchUserCandidatesSlice,
} from '@mentorcliq/storage';
import { MQIcon } from '@mentorcliq/ui';

import { PAGEABLE_SIZE } from 'definitions/configs';
import { APP_GLOBAL_MESSAGES } from 'definitions/messages';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import MQButton from 'modules/MQButton';
import MQModal from 'modules/MQModal';
import MQTooltip from 'modules/MQTooltip';

import AppFormattedMessage from 'formatters/AppFormattedMessage';

import UsersSearchBox from 'common/UsersSearchBox';

interface SwitchUserModalProps {
  visible: boolean;
  hide: () => void;
  switchToUser: (id: number) => void;
}

const SwitchUserModal: FC<SwitchUserModalProps> = ({ visible, switchToUser, hide }) => {
  const dispatch = useAppDispatch();
  const candidatesLoadingState = useAppSelector(({ switchUserCandidates }) =>
    getSwitchUserCandidatesLoadingState(switchUserCandidates),
  );
  const candidatesTotalCount = useAppSelector(({ switchUserCandidates }) =>
    getSwitchUserCandidatesTotalElements(switchUserCandidates),
  );
  const candidates = useAppSelector(({ switchUserCandidates }) => getSwitchUserCandidates(switchUserCandidates));

  const resetUsers = useCallback(() => {
    dispatch(switchUserCandidatesSlice.actions.clear());
  }, [dispatch]);

  const queryUsers = useCallback(
    (params: { keyword: string; size: number }) => {
      dispatch(
        switchUserCandidatesQueryThunk({
          keyword: params.keyword,
          size: params.size,
        }),
      );
    },
    [dispatch],
  );

  const rows = useMemo(
    () =>
      candidates?.map((item) => ({
        tools: (
          <MQTooltip
            placement="top"
            overlay={
              <AppFormattedMessage
                defaultMessage="Switch to use MentorcliQ as selected user."
                description="[SwitchUserModal] Switch button tooltip"
                id="switch.user.modal.switch.button.tooltip"
              />
            }
          >
            <MQButton
              dataTestId="switch-user-modal-change"
              variant="secondary"
              shape="square"
              startIcon={<MQIcon.Svg icon="exchange__r" />}
              onClick={() => switchToUser(item.id)}
            />
          </MQTooltip>
        ),
        data: {
          id: item.id,
          picture: item.profile.profilePictureFileHandle,
          name: item.realName,
          email: item.email,
        },
      })) ?? null,
    [candidates, switchToUser],
  );

  return (
    <MQModal show={visible} scrollable centered onHide={hide}>
      <MQModal.Header closeButton>
        <MQModal.Title>
          <AppFormattedMessage
            defaultMessage="Switch User Account"
            description="[SwitchUserModal] Title"
            id="switch.user.modal.title"
          />
        </MQModal.Title>
      </MQModal.Header>

      <MQModal.Body>
        <UsersSearchBox
          data={rows}
          loading={candidatesLoadingState.isPending}
          total={candidatesTotalCount}
          size={PAGEABLE_SIZE}
          queryData={queryUsers}
          resetData={resetUsers}
        />
      </MQModal.Body>
      <MQModal.Footer>
        <MQButton dataTestId="switch-user-modal-cancel" variant="tertiary" onClick={hide}>
          <AppFormattedMessage {...APP_GLOBAL_MESSAGES.cancel} />
        </MQButton>
      </MQModal.Footer>
    </MQModal>
  );
};

export default SwitchUserModal;
