import './style.scss';

import { FC, ReactNode, useCallback, useMemo, useState } from 'react';

import { MQBadge, MQIcon } from '@mentorcliq/ui';
import classNames from 'classnames';

import MQLoader from 'modules/MQLoader';
import MQOverlay from 'modules/MQOverlay';
import MQVirtualized from 'modules/MQVirtualized';

interface MQFormMultiselectOptionProps<T> {
  label: string;
  value: T;
}

interface MQFormMultiselectProps {
  className?: string;
  size?: number;
  dataTestId?: string;
  isValid?: boolean;
  isInvalid?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  compact?: boolean;
  name: string;
  placeholder?: string;
  values?: string[];
  onSelect?: (values: string[]) => void;
  options?: MQFormMultiselectOptionProps<string>[];
  newLabel?: ReactNode;
  empty?: ReactNode;
  allowNew?: boolean;
  rootId?: string;
  loading?: boolean;
  independentOptions?: boolean;
  onSearch?: (value: string) => void;
}

const MQFormMultiselect: FC<MQFormMultiselectProps> = ({
  multiple = true,
  onSelect,
  className = '',
  isValid = false,
  isInvalid = false,
  disabled = false,
  compact = false,
  values = [],
  options = [],
  size = 50,
  name,
  placeholder,
  dataTestId = 'mq-multiselect',
  newLabel = 'New Label',
  allowNew = false,
  empty,
  loading = false,
  rootId = 'root',
  independentOptions,
  onSearch,
}) => {
  const [term, setTerm] = useState('');
  const [rectData, setRectData] = useState({
    width: 0,
    height: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  const formattedOptions = useMemo(() => {
    if (allowNew) {
      const initial = [];

      if (term.length) {
        initial.push({
          label: `${term} ${newLabel}`,
          value: term,
        });
      }

      values
        .filter((value) => !options.find((item) => item.value === value))
        .forEach((item) => {
          initial.push({
            label: item,
            value: item,
          });
        });

      return [...options, ...initial];
    }

    if (independentOptions) {
      return values.map((item) => ({
        label: item,
        value: item,
      }));
    }

    return options;
  }, [allowNew, independentOptions, options, term, values, newLabel]);

  const filteredOptions = useMemo(() => {
    const search = term.toLowerCase();
    if (onSearch) {
      onSearch(search);
      return formattedOptions;
    } else {
      return formattedOptions.filter(({ value, label }) => label.toLowerCase().match(search) || value.match(search));
    }
  }, [formattedOptions, onSearch, term]);

  const selected = useMemo(
    () => formattedOptions.filter(({ value }) => values.includes(value)),
    [formattedOptions, values],
  );

  const noResult = useMemo(() => {
    if (empty) {
      return <div className="mq-form-multiselect__empty">{empty}</div>;
    }
  }, [empty]);

  const handleSelect = useCallback(
    (value: string) => {
      if (values?.includes(value)) {
        onSelect?.(values?.filter((val) => val !== value));
      } else {
        onSelect?.([...values, value]);
      }
      setTerm('');
    },
    [onSelect, values],
  );

  const label = useMemo(() => {
    if (!selected.length) {
      return placeholder;
    }
  }, [placeholder, selected.length]);

  const onCloseBadge = useCallback(
    (value: string) => {
      onSelect?.(values.filter((item) => item !== value));
    },
    [onSelect, values],
  );

  const selectedBadges = useMemo(
    () =>
      selected.map(({ value, label }) => (
        <MQBadge.Closable key={`${value}`} onClose={() => onCloseBadge(value)}>
          <span
            dangerouslySetInnerHTML={{
              __html: label,
            }}
          />
        </MQBadge.Closable>
      )),
    [onCloseBadge, selected],
  );

  return (
    <div
      data-testid={`mq-form-input-${dataTestId || name}`}
      className={classNames('mq-form-multiselect', className, {
        compact,
        multiple,
        ['is-valid']: isValid,
        ['is-invalid']: isInvalid,
        disabled,
      })}
    >
      <MQOverlay>
        {(state, setState) => (
          <>
            <MQOverlay.Trigger state={state} setState={setState} triggers={['click', 'focus']} toggle>
              <div
                ref={(node) => {
                  const rect = node?.getBoundingClientRect();
                  if (rect) {
                    if (rect.top !== rectData.top || rect.bottom !== rectData.bottom || rect.right !== rectData.right) {
                      setRectData((prevState) => ({
                        ...prevState,
                        width: rect.width,
                        height: rect.height,
                        top: rect.top,
                        bottom: rect.bottom,
                        left: rect.left,
                        right: rect.right,
                      }));
                    }
                  }
                }}
                className="mq-form-multiselect__toggle"
              >
                <div className="mq-form-multiselect__values">
                  {selectedBadges}
                  <input
                    type="text"
                    onInput={(e) => {
                      setTerm(e.currentTarget.value);
                    }}
                    value={term}
                    placeholder={label}
                    className="mq-form-multiselect__editable"
                  />
                </div>
                <div className="mq-form-multiselect__toggle-actions">
                  {loading && (
                    <div className="mq-form-multiselect__toggle-loading">
                      <MQLoader.Dots size="sm" />
                    </div>
                  )}
                  {(!!selected.length || term) && (
                    <button
                      type="button"
                      onClick={() => {
                        onSelect?.([]);
                        setTerm('');
                      }}
                      className="mq-form-multiselect__toggle-button"
                    >
                      <MQIcon.Svg icon="times" />
                    </button>
                  )}
                  <button type="button" className="mq-form-multiselect__toggle-button">
                    {state ? <MQIcon.Svg icon="angle-down" /> : <MQIcon.Svg icon="angle-up" />}
                  </button>
                </div>
              </div>
            </MQOverlay.Trigger>
            {state && (
              <MQOverlay.Wrapper
                rootId={rootId}
                rect={rectData}
                state={state}
                setState={setState}
                placement="bottom"
                hideOnScroll
              >
                {({ triggerWidth }) => (
                  <div style={{ minWidth: triggerWidth }} className="mq-form-multiselect__options">
                    {filteredOptions.length ? (
                      <MQVirtualized items={filteredOptions} size={size}>
                        {({ visible }) =>
                          visible.map(({ item, key }) => (
                            <button
                              type="button"
                              onClick={() => {
                                handleSelect(item.value);
                              }}
                              className={classNames('mq-form-multiselect__option', {
                                selected: values?.includes(item.value),
                              })}
                              key={key}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.label,
                                }}
                              />
                            </button>
                          ))
                        }
                      </MQVirtualized>
                    ) : (
                      noResult
                    )}
                  </div>
                )}
              </MQOverlay.Wrapper>
            )}
          </>
        )}
      </MQOverlay>
    </div>
  );
};

export default MQFormMultiselect;
